import React, { FC } from 'react'
import './styles.css'

const UnderConstruction: FC = (): JSX.Element => {

	return (
		<h1>UNDER CONSTRUCTION</h1>
	)
}

export default UnderConstruction